import React from 'react';
import './App.css';
import ProductIntroPage from "./Product/ProductIntro";
import LanguageSwitcher from "./Commons/Language/LanguageSwitcher";
import Navbar from "./Commons/Nvabar/Navbar";
import Home from "./Home/Home";
import About from "./About/About";
import './i18n';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from "./Commons/Bottom/Footer";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import UserAgreement from "./UserAgreement/UserAgreement"; // 将 Switch 替换为 Routes

function App() {
    return (
        <Router>
            <div className="App">
                <Navbar/>
                <LanguageSwitcher/>
                <hr/>
                <Routes>
                    <Route path="/" element={<Home/>}/> {}
                    <Route path="/about" element={<About/>}/> {}
                    <Route path="/product" element={<ProductIntroPage/>}/> {}
                    <Route path="/privacy-policy" element={ <PrivacyPolicy/> }/> {}
                    <Route path="/user-agreement" element={ <UserAgreement />} /> {}
                </Routes>
                <hr/>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;