import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    const { i18n } = useTranslation();
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch(`/static/locales/PrivacyPolicy_${i18n.language}.txt`)
            .then(response => response.text())
            .then(data => setContent(data));
    }, [i18n.language]);

    return <div className="privacy-policy" dangerouslySetInnerHTML={{ __html: content }} />;
}

export default PrivacyPolicy;