import {Carousel} from "react-responsive-carousel";
import image1 from "../Images/banner/1.png";
import image2 from "../Images/banner/2.png";
import image3 from "../Images/banner/3.png";
import image4 from "../Images/banner/4.png";

import p1 from "../Images/product/p1.png";
import p2 from "../Images/product/p2.png";
import p3 from "../Images/product/p3.png";
import p4 from "../Images/product/p4.png";

import './Home.css';
import ProductIntro from "../Component/product";
import {useTranslation} from "react-i18next";

function Home() {
    const { t } = useTranslation(); // 获取 t 函数
    return (
        <div>
            <Carousel autoPlay infiniteLoop showThumbs={false} className="carousel">
                <div className="carousel-slide">
                    <img src={image1} alt="Image 1"/>
                </div>
                <div className="carousel-slide">
                    <img src={image2} alt="Image 2"/>
                </div>
                <div className="carousel-slide">
                    <img src={image3} alt="Image 3"/>
                </div>
                <div className="carousel-slide">
                    <img src={image4} alt="Image 4"/>
                </div>
            </Carousel>
            <div className="introduction">
                <h2>{t('Introduction')}</h2>
                <br/>
                <br/>
                <br/>
                <p>
                    {t('IntroductionP1')}
                </p>
                <p>
                    {t('IntroductionP2')}
                </p>
            </div>
            <div className="product-intro-pz">
                <ProductIntro
                    title="GPing"
                    description={t('ProductT1')}
                    imageUrl={p1}/>
                <ProductIntro
                    title="GPing"
                    description={t('ProductT1')}
                    imageUrl={p2}/>
                <ProductIntro
                    title="GPing"
                    description={t('ProductT1')}
                    imageUrl={p3}/>
                <ProductIntro
                    title="GPing"
                    description={t('ProductT1')}
                    imageUrl={p4}/>
            </div>
            <div className="linker">
                <h2> {t('ContactUs')}</h2>
                <br/>
                <br/>
                <br/>
                <p>
                    {t('Email')}：admin#acceptplay.com
                </p>
            </div>
        </div>
    );
}

export default Home;