import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

function LanguageSwitcher() {
    const { i18n } = useTranslation();

    const changeLanguage = (selectedOption) => {
        i18n.changeLanguage(selectedOption.value);
        localStorage.setItem('i18nextLng', selectedOption.value);
    };

    const languageOptions = [
        { value: 'en', label: 'English' },
        { value: 'zh', label: '简体中文' },
        // { value: 'zh-TW', label: '繁體中文' },
        // { value: 'en-GB', label: 'English (United Kingdom)' },
        // { value: 'en-CA', label: 'English (Canada)' },
        // { value: 'en-AU', label: 'English (Australia)' },
        // { value: 'en-IN', label: 'English (India)' },
        // { value: 'de', label: 'Deutsch' },
        // { value: 'es', label: 'Español' },
        // { value: 'fr', label: 'Français' },
        // { value: 'it', label: 'Italiano' },
        // { value: 'ja', label: '日本語' },
        // { value: 'ko', label: '한국어' },
        // { value: 'pt', label: 'Português' },
    ];

    return (
        <div className="language-switcher">
            <Select
                options={languageOptions}
                onChange={changeLanguage}
                value={languageOptions.find(option => option.value === i18n.language)}
                menuPlacement="top"
            />
        </div>
    );
}

export default LanguageSwitcher;