import React from 'react';
import './ProductIntro.css';
import p1 from "../Images/product/p1.png";
import p2 from "../Images/product/p2.png";
import p3 from "../Images/product/p3.png";
import p4 from "../Images/product/p4.png";
import ProductIntro from "../Component/product";
import {useTranslation} from "react-i18next";

function ProductIntroPage() {
    const { t } = useTranslation();
    return (
        <div className="product-intro">
            <ProductIntro
                title="GPing"
                description={t('ProductT1')}
                imageUrl={p1}/>
            <ProductIntro
                title="GPing"
                description={t('ProductT1')}
                imageUrl={p2}/>
            <ProductIntro
                title="GPing"
                description={t('ProductT1')}
                imageUrl={p3}/>
            <ProductIntro
                title="GPing"
                description={t('ProductT1')}
                imageUrl={p4}/>
        </div>
    );
}

export default ProductIntroPage;