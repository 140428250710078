import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const browserLang = navigator.language || navigator.languages[0];
const userLang = localStorage.getItem('i18nextLng');


i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    "Welcome to React": "Welcome to React",
                    "Home": "Home",
                    "AboutUs": "About Us",
                    "ProductIntro": "Product Introduction",
                    "UserAgreement": "User Agreement",
                    "PrivacyPolicy": "Privacy Policy",
                    "Introduction":"Introduction",
                    "IntroductionP1":"GPing is an innovative brand-introduction social application that leverages the most advanced artificial intelligence technology to provide users with a unique social experience. As a platform focused on brand interaction, GPing allows users to explore and discover various brand stories, participate in brand events, and establish deeper connections with their favorite brands. Through intelligent algorithms, GPing personalized recommendations of relevant content, ensuring that users are always informed about the latest and hottest brand trends.",
                    "IntroductionP2":"GPing's interface is clean and user-friendly, ensuring that users can easily navigate and enjoy a smooth social experience. Whether fashion enthusiasts, tech geeks, or lifestyle zealots, everyone can find their interests on GPing. Moreover, GPing's AI features not only enhance the relevance and interactivity of content but also continually optimize the experience by learning users' preferences and behavior patterns. Join GPing to let brand stories inspire you, and together with like-minded individuals, shape a more personalized and interactive social world.",
                    "ContactUs":"contact us",
                    "Email":"email",
                    "ProductT1":"GPing is an innovative brand introduction social application...",
                }
            },
            zh: {
                translation: {
                    "Welcome to React": "欢迎使用React",
                    "Home": "首页",
                    "AboutUs": "关于我们",
                    "ProductIntro": "产品介绍",
                    "UserAgreement": "用户协议",
                    "PrivacyPolicy": "隐私政策",
                    "Introduction":"介绍",
                    "IntroductionP1":"GPing是一个创新的品牌介绍类社交应用程序，它利用最先进的人工智能技术为用户提供独一无二的社交体验。作为一个专注于品牌互动的平台，GPing允许用户探索和发现各种品牌故事，参与品牌活动，并与喜爱的品牌建立更深层次的连接。通过智能算法，gping个性化推荐相关内容，确保用户始终了解最新、最热门的品牌动态。",
                    "IntroductionP2":"GPing的界面简洁友好，确保用户能够轻松导航和享受流畅的社交体验。无论是时尚爱好者、科技迷还是生活方式狂热分子，每个人都可以在GPing上找到他们的兴趣所在。此外，gping的AI功能不仅提高了内容的相关性和互动性，还通过学习用户的偏好和行为模式不断优化体验。加入gping，让品牌故事激发您的灵感，与志同道合的人一起，塑造一个更加个性化和互动的社交世界。",
                    "ContactUs":"联系我们",
                    "Email":"邮箱",
                    "ProductT1":"GPing是一个创新的品牌介绍类社交应用程序...",
                }
            },
            "en-GB": {
                translation: {
                    "Welcome to React": "Welcome to React",
                    // 更多英国英文翻译...
                }
            },
            "en-CA": {
                translation: {
                    "Welcome to React": "Welcome to React",
                    // 更多加拿大英文翻译...
                }
            },
            "en-AU": {
                translation: {
                    "Welcome to React": "Welcome to React",
                    // 更多澳大利亚英文翻译...
                }
            },
            "en-IN": {
                translation: {
                    "Welcome to React": "Welcome to React",
                    // 更多印度英文翻译...
                }
            },
            de: {
                translation: {
                    "Welcome to React": "Willkommen bei React",
                    // 更多德文翻译...
                }
            },
            es: {
                translation: {
                    "Welcome to React": "Bienvenido a React",
                    // 更多西班牙文翻译...
                }
            },
            fr: {
                translation: {
                    "Welcome to React": "Bienvenue à React",
                    // 更多法文翻译...
                }
            },
            it: {
                translation: {
                    "Welcome to React": "Benvenuto a React",
                    // 更多意大利文翻译...
                }
            },
            ja: {
                translation: {
                    "Welcome to React": "Reactへようこそ",
                    // 更多日文翻译...
                }
            },
            ko: {
                translation: {
                    "Welcome to React": "React에 오신 것을 환영합니다",
                    // 更多韩文翻译...
                }
            },
            pt: {
                translation: {
                    "Welcome to React": "Bem-vindo ao React",
                    // 更多葡萄牙文翻译...
                }
            },
            "zh-TW": {
                translation: {
                    "Welcome to React": "歡迎來到 React",
                    // 更多繁体中文翻译...
                }
            },
            // 更多语言...
        },
        lng: userLang || browserLang,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;