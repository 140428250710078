import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './UserAgreement.css';

function UserAgreement() {
    const { i18n } = useTranslation();
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch(`/static/locales/UserAgreement_${i18n.language}.txt`)
            .then(response => response.text())
            .then(data => setContent(data));
    }, [i18n.language]);

    return <div className="user-agreement" dangerouslySetInnerHTML={{ __html: content }} />;
}

export default UserAgreement;