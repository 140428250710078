import React from 'react';
import './Navbar.css';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation 钩子

function Navbar() {
    const { t } = useTranslation(); // 获取 t 函数

    return (
        <nav className="navbar">
            <a href="/">{t('Home')}</a>
            <a href="/product">{t('ProductIntro')}</a>
            <a href="/about">{t('AboutUs')}</a>
            <a href="/user-agreement">{t('UserAgreement')}</a>
            <a href="/privacy-policy">{t('PrivacyPolicy')}</a>
        </nav>
    );
}

export default Navbar;