import React from 'react';

const ProductIntro = ({ title, description, imageUrl }) => {
    return (
        <div style={styles.container}>
            <h1 style={styles.title}>{title}</h1>
            <p style={styles.description}>{description}</p>
            <div style={styles.imageContainer}>
                <img src={imageUrl} alt="Product" style={styles.image} />
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
    },
    title: {
        fontSize: '2em',
        color: '#333'
    },
    description: {
        fontSize: '1em',
        color: '#666',
        textAlign: 'center',
        maxWidth: '600px'
    },
    imageContainer: {
        margin: '20px 0'
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '8px'
    }
};

export default ProductIntro;
