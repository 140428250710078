import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './About.css';

function About() {
    const { i18n } = useTranslation();
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch(`/static/locales/About_${i18n.language}.txt`)
            .then(response => response.text())
            .then(data => setContent(data));
    }, [i18n.language]);

    return <div className="about" dangerouslySetInnerHTML={{ __html: content }} />;
}

export default About;